import { Loader } from '@instech/components';
import {
  addContactAsync,
  removeContactAsync,
  mutateContactsAsync,
  useContacts
} from '@/services/contactsServices';
import { ToggleInlineForm } from '@/components/shared/Form/ToggleInlineForm';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { NewContactForm } from './core/NewContactForm';
import { sortByName } from './core/sortByName';
import { ContactsTable } from './ContactsTable';

export const ContactsEditView = () => {
  const caseId = useCaseIdFromUrl();

  const contacts = useContacts(caseId);
  const { data: status } = useCaseStatus(true);

  if (!contacts || !status) {
    return <Loader />;
  }
  contacts.sort(sortByName);

  const submitNewContactAsync = async contact => {
    const result = await addContactAsync(caseId, contact);
    const newList = [...contacts, result];
    newList.sort(sortByName);
    await mutateContactsAsync(caseId, newList);
  };

  const deleteContactAsync = async contactId => {
    await removeContactAsync(caseId, contactId);
    const newList = contacts.filter(x => x.id !== contactId);
    await mutateContactsAsync(caseId, newList);
  };

  return (
    <>
      <ToggleInlineForm title="New contact" openLabel="Contact" startOpen={contacts.length === 0}>
        {
         ({ onClose }) => <NewContactForm submit={submitNewContactAsync} onClose={onClose} />
        }
      </ToggleInlineForm>
      {contacts.length > 0 && <ContactsTable data={contacts} remove={deleteContactAsync} caseId={caseId} />}
    </>
  );
};
