import { useCompanyParticulars } from '@/services/companyParticularsServices';
import { roles } from '@/components/AppRouting/roles';
import { Loader } from '@instech/components';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { useCaseStatus } from '@/services/caseStatusServices';
import { CompanyParticularsEditableView } from './pages/CompanyParticularsEditableView/CompanyParticularsEditableView';
import { CompanyParticularsReadOnlyView } from './pages/CompanyParticularsReadOnlyView';

export const CompanyParticularsTool = () => {
  const { data: particulars, error } = useCompanyParticulars();
  const { data: status } = useCaseStatus(true);

  const role = useCurrentRole();
  const isReadOnly = role?.name === roles.claimHandler || status?.isClosed;

  if (error) {
    throw new Error(error);
  }
  if (!particulars) return <Loader />;

  return (
    isReadOnly ? <CompanyParticularsReadOnlyView /> : <CompanyParticularsEditableView />
  );
};
export const openCompanyParticularsTool = {
  component: CompanyParticularsTool,
  options: {
    title: 'Company\'s particulars',
    size: 'small',
    footer: true // Deprecated
  },
  args: {}
};
