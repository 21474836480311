import styled from 'styled-components';

// eslint-disable-next-line max-len
const message = 'This case is closed. Due to GDPR requirements, any documents and pictures marked as containing Personal Identifiable Information are now hidden for all involved parties. This data will be permanently deleted 10 years after the case is closed.';

const Heading = styled.h3`
  color: ${props => props.theme.darkGray};
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 12px;
`;
const Text = styled.p`
  margin-top: 0;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    margin-right: 200px;
  }
`;

export const FilesHiddenGdprMessage = ({ className }: { className?: string }) => (
  <div className={className}>
    <Heading>Files containing Personal Identifiable Information are hidden</Heading>
    <Text>{message}</Text>
  </div>
);
