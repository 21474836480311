import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { CompanyParticular, Guid } from '@/types';
import { useModalContext } from '@/components/modal/ModalContext';
import {
  Divider, ButtonGroup, BoxButton
} from '@instech/components';
import {
  addCompanyParticularAsync, deleteCompanyParticularsAsync, restoreDeletedCompanyParticularAsync, useCompanyParticulars
} from '@/services/companyParticularsServices';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { RestoreDeletedItemPopup, useRestoreData } from '@/components/shared/RestoreDeletedItem';
import { NewParticularForm } from '../../core/NewParticularForm';
import { CompanyParticularRowView } from './CompanyParticularRowView';
import { CompanyParticularRowEdit } from './CompanyParticularRowEdit';

const Wrapper = styled.div`
  position: relative;
`;

const RestorePopup = styled(RestoreDeletedItemPopup)`
  right: 0;
`;

interface Props {
  item: CompanyParticular;
  isEditMode: boolean;
  onDelete: () => void;
  toggleEdit: () => void;
}
const ParticularRow: FunctionComponent<Props> = ({ item, isEditMode, onDelete, toggleEdit }) => {
  if (isEditMode) {
    return <CompanyParticularRowEdit initialData={item} toggleEditMode={toggleEdit} />;
  }
  return <CompanyParticularRowView item={item} onDelete={onDelete} toggleEdit={toggleEdit} />;
};

export const CompanyParticularsEditableView = () => {
  const { close } = useModalContext();
  const caseId = useCaseIdFromUrl();
  const [editItem, setEditItem] = useState<Guid | undefined>(undefined);

  const { state: restoreState, showRestoreDialog, hideRestoreDialog } = useRestoreData();

  const { data: particulars, mutate } = useCompanyParticulars();

  const submitNewParticularAsync = async (particular: CompanyParticular) => {
    const result = await addCompanyParticularAsync(caseId, particular);
    if (result) {
      void mutate(existingItems => [...(existingItems ?? []), result], false);
    }
  };

  const onRestoreDeleted = (id: string) => async () => {
    const result = await restoreDeletedCompanyParticularAsync(caseId, id);
    void mutate(result);
  };

  const deleteParticular = async (itemId: Guid) => {
    void mutate(existingItems => existingItems?.filter(x => x.id !== itemId), false);
    await deleteCompanyParticularsAsync(caseId, itemId);
    showRestoreDialog(onRestoreDeleted(itemId));
  };

  const toggleEdit = (id: Guid) => id === editItem
    ? setEditItem(undefined)
    : setEditItem(id);

  return (
    <>
      <Wrapper>
        {particulars.map(item => (
          <ParticularRow
            key={item.id}
            item={item}
            isEditMode={editItem === item.id}
            onDelete={() => deleteParticular(item.id)}
            toggleEdit={() => toggleEdit(item.id)}
          />

        ))}
        <NewParticularForm
          submit={submitNewParticularAsync}
          showWhenEmpty={particulars.length === 0}
          hideRestoreDialog={hideRestoreDialog}
        />
        <RestorePopup
          {...restoreState}
        />
      </Wrapper>
      <Divider padding="0px" />
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </>
  );
};
