import styled from 'styled-components';
import { ToolButton } from '@/components/shared/ToolButton';
import { useModalContext } from '@/components/modal/ModalContext';
import { useManifest } from '@/services/manifestServices';
import { getReportTools } from '@/services/toolsServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { BoxButton } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useFeatureFlag } from '@/providers/featureFlagProvider';
import { createRemoteSurveyAsync } from '@/services/remoteSurvey';
import { ToolPane } from './ToolPane';

const FlexWrappedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.lightBlue};
  justify-content: flex-start;
  @media(max-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: space-around;
  };
`;

const ButtonArea = styled.div`
  padding-top: 20px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const NavButtons = () => {
  const { open } = useModalContext();
  const role = useCurrentRole();
  const enableVSightSession = useFeatureFlag('vSightSession');
  const caseId = useCaseIdFromUrl();
  const { data: manifest } = useManifest();
  const tools = getReportTools(manifest?.reportTools, role.name);

  const submitNewRemoteSurvey = async () => {
    const data = await createRemoteSurveyAsync(caseId);
    window.open(data.remoteSurveyLink, '_blank', 'noreferrer');
  };

  // For now we just create the session with the caseId without modal
  // Need to decide the proper flow for this
  // Replace button onclick with the next line function for modal display
  // const showVSightSessionCreation = openVSightTool({ caseId });

  return (
    <ToolPane title="Surveyor's tools">
      <FlexWrappedRow>
        {tools.map(each => (
          <ToolButton
            max-width="75px"
            key={each.label}
            onClick={() => open(each.onclick)}
            icon={<each.icon />}
          >
            {each.label}
          </ToolButton>
        ))}
      </FlexWrappedRow>
      {enableVSightSession && (
        <ButtonArea>
          <BoxButton inverted onClick={() => submitNewRemoteSurvey()}>Start Remote Survey</BoxButton>
        </ButtonArea>
      )}
    </ToolPane>
  );
};
