import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FeedbackContainer } from '../immediateFeedback/FeedbackContainer';
import { NavButtons } from './NavButtons';

const ContentArea = styled.div`
  display: flex;
  background: ${props => props.theme.white};
  width: 100%;    
  box-sizing: border-box; 
`;

const ToolArea = styled.div`
  flex-grow: 1;
  color: ${props => props.theme.deepAzureBlue};
  background: ${props => props.theme.lightBlue};
`;

const FeedbackArea = styled.div`
  width: 400px;
  margin-left: 20px;
  flex-shrink: 0;
  box-sizing: border-box; 
  color: ${props => props.theme.white};
  background: ${props => props.theme.lightBlue};
`;

interface ToolsAndFeedbackProps {
  readonly?: boolean;
}
export const ToolsAndFeedback: FunctionComponent<ToolsAndFeedbackProps> = ({ readonly = false }) => (
  <ContentArea>
    <ToolArea>
      <NavButtons />
    </ToolArea>
    <FeedbackArea>
      <FeedbackContainer readonly={readonly} />
    </FeedbackArea>
  </ContentArea>
);
