import {
  Checkbox,
  OccurrenceSelect,
  StatusTypeRadio, TimezonePicker
} from '@/components/shared/Form/FormFields';
import { getLabelValuePairs, getOptions } from '@/components/shared/Form/fields/Dropdown/OccurrenceSelect';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { useTimelineOptions } from '@/services/configurationServices';
import { TimelineEntryGroup, TimelineItem } from '@/types';
import { constructDateTime } from '@/utils/date';
import {
  DatePicker,
  Divider,
  DropdownCreatable,
  FormField, FormRow,
  FormikWithPrompt as Formik,
  TextField, TimePicker
} from '@instech/components';
import { Form, FormikProps } from 'formik';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { getDropdownOptions, getFormFieldSchema } from '../../utils/timelineOptionsUtils';
import {
  clearInvalidFields, eventModel, eventSchema, getFieldStates
} from '../core/eventFormUtils';
import { EventFormTypeSelection } from '../core/EventFormTypeSelection';
import { EventFormModel } from '../core/types';
import { useResetTouched } from '../core/useResetTouch';

const MainSection = styled.div`
  padding: 20px 20px 0px;
`;

interface InnerFormProps extends FormikProps<EventFormModel> {
  timelineOptions?: TimelineEntryGroup[];
  locations: any; // TODO: Fix typing
  onClose: () => void;
}
const EditEventInnerForm: FC<InnerFormProps> = ({
  values,
  dirty,
  isSubmitting,
  handleSubmit,
  setTouched,
  timelineOptions,
  locations,
  onClose
}) => {
  const dropdownOptions = getDropdownOptions(timelineOptions);
  const formFieldSchema = getFormFieldSchema(timelineOptions);

  // https://github.com/formium/formik/pull/1746
  /* Get and set up conditionals. These have to be sent to Formik to get
  them to Yup. Param-reassign isn't too great, but doing it like this prevents a re-render loop */
  const fieldSchema = getFieldStates(values, formFieldSchema);
  // eslint-disable-next-line no-param-reassign
  values.fieldSchema = fieldSchema;

  // Set the entire form to untouched so that form validation errors don't appear when
  // the user changes any of the options in the dependency array.
  const resetValueCheck = values.movementType || values.eventType || values.typeOfActivity?.value;
  useResetTouched(eventModel, resetValueCheck, setTouched);

  const dateTime = constructDateTime(values.eventDate, values.eventTime);
  const customActivity = values.typeOfActivity?.value === 'Custom';
  const showMainForm = (values.typeOfActivity && fieldSchema);

  return (
    <Form>
      <EventFormTypeSelection
        dropdownOptions={dropdownOptions}
      />
      {showMainForm && (
        <MainSection>
          {customActivity && (
            <FormRow marginBottom="10px">
              <FormField>
                <TextField
                  name="customActivityName"
                  label="Activity name"
                  placeholder="Activity name"
                />
              </FormField>
            </FormRow>
          )}
          <FormRow gutter="20px" marginBottom="10px">
            <FormField>
              <DropdownCreatable
                name="location"
                label="Location"
                placeholder="Select location"
                options={locations}
              />
            </FormField>
          </FormRow>
          <FormRow gutter="20px" marginBottom="10px">
            <FormField>
              <DatePicker
                name="eventDate"
                label="Date"
              />
            </FormField>
          </FormRow>
          <FormRow gutter="20px" marginBottom="10px">
            <FormField span={4}>
              <TimePicker
                name="eventTime"
                label="Time (if significant)"
              />
            </FormField>
            <FormField span={8}>
              <TimezonePicker
                name="timezoneId"
                label="Time zone"
                dateTime={dateTime}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField span={5}>
              <StatusTypeRadio
                name="timeStatus"
                label="Time status"
                customId="modal"
              />
            </FormField>
          </FormRow>
          {fieldSchema.selectOccurrences && (
            <FormRow marginBottom="10px">
              <FormField span={12}>
                <OccurrenceSelect
                  label="Occurrences"
                  name="occurrences"
                />
              </FormField>
            </FormRow>
          )}
          <FormRow marginBottom="10px">
            <FormField span={12}>
              <TextField
                name="comment"
                placeholder="Optional ..."
                label="Comment"
              />
            </FormField>
          </FormRow>
          <FormRow marginBottom="10px">
            <FormField>
              <Checkbox
                // fieldId="modal"
                name="includeInReport"
                rightLabel="Include in report?"
                noErrors
              />
            </FormField>
          </FormRow>
        </MainSection>
      )}
      <Divider />
      <SaveCloseButtons
        disableSave={!dirty}
        isSubmitting={isSubmitting}
        onSave={handleSubmit}
        onClose={onClose}
        closeLabel="Cancel"
        alignRight
        reverse
      />
    </Form>
  );
};

interface FormProps {
  event: TimelineItem;
  onSubmit: (event: TimelineItem) => Promise<boolean>;
  onClose: () => void;
  locations: any; // TODO: Fix typing
}
export const EditEventForm: FC<FormProps> = ({ event, onSubmit, onClose, locations }) => {
  const { data: timelineOptions } = useTimelineOptions({ swrOptions: { suspense: true } });

  // TODO: Clean up construction here
  const initialValues: EventFormModel = useMemo(() => ({
    ...event,
    fieldSchema: {},
    customActivityName: event.customActivityName ?? '',
    vesselStatus: event.timeStatus,
    eventTime: event.eventTime ?? '',
    location: {
      label: event.location,
      value: event.location,
    },
    typeOfActivity: {
      label: event.typeOfActivity,
      value: event.typeOfActivity
    },
    occurrences: getLabelValuePairs(event.occurrences)
  }), [event]);

  return (
    <Formik
      formId="edit-event-form"
      initialValues={initialValues}
      validationSchema={eventSchema}
      onSubmit={async (values, { resetForm }) => {
        const clearedValues = clearInvalidFields(values, values.fieldSchema);
        const payload: TimelineItem = {
          ...clearedValues,
          location: values.location?.value ?? '',
          typeOfActivity: values.typeOfActivity?.value ?? '',
          occurrences: getOptions(clearedValues.occurrences)
        };
        const success = await onSubmit(payload);
        if (success) {
          resetForm({ values: { ...values } });
          onClose();
        }
      }}>
      {formikProps => (
        <EditEventInnerForm
          {...formikProps}
          timelineOptions={timelineOptions}
          locations={locations}
          onClose={onClose}
        />
      )}
    </Formik>
  );
};
