import styled from 'styled-components';
import { formatTimeOfDay, getTimelineDate } from '@/utils/date';
import { eventTypeProp } from '@/utils/timelineTypes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  grid-area: center;
  font-size: 16px;
`;

interface DateProps {
  eventType: eventTypeProp;
}
const Date = styled.span<DateProps>`
  color: ${props => props.theme.timeline[props.eventType].dark || props.theme.marineBlue};
  font-weight: bold;

`;

const TimeOfDay = styled.span`
  color: ${props => props.theme.placeholderGray};
`;

const getTimeOfDay = (localTimeOfDay: string | undefined, utcDate: string, localDate: string, displayInUtcTime: boolean) => {
  if (displayInUtcTime) {
    const timePart = utcDate.split('T')[1].split('.')[0];
    return formatTimeOfDay(timePart);
  }
  if (localTimeOfDay) {
    return formatTimeOfDay(localTimeOfDay);
  }
  // 'localTimeOfDay' is not defined on archived reports, therefore we
  // have to extract the time part from 'localDate' in those cases. In
  // non-archived cases the time part of 'localDate' is always '00:00:00'
  const timePart = localDate.split('T')[1].split('.')[0];
  return formatTimeOfDay(timePart);
};

interface Props {
  eventType: eventTypeProp;
  utcDate: string;
  displayInUtcTime: boolean;
  localDate: string;
  localTimeOfDay?: string;
  hasTime: boolean;
}
export const EventDate = ({ eventType, utcDate, localDate, displayInUtcTime, localTimeOfDay, hasTime }: Props) => {
  const timeOfDay = hasTime ? getTimeOfDay(localTimeOfDay, utcDate, localDate, displayInUtcTime) : undefined;
  return (
    <Wrapper>
      <Date eventType={eventType}>
        {getTimelineDate(displayInUtcTime ? utcDate : localDate)}
      </Date>
      {timeOfDay && (
      <TimeOfDay>
        {timeOfDay}
      </TimeOfDay>
      ) }
    </Wrapper>
  );
};
