import styled, { css } from 'styled-components';
import {
  Add, Edit, Trash
} from '@instech/icons';
import { eventTypeProp, TimelineEventProps } from '@/utils/timelineTypes';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { useModalContext } from '@/components/modal/ModalContext';
import { useTimelineContext } from '@/components/pages/edit/core/TimelineContext';
import { showBackgroundOnPrint } from '@/utils/styles';
import { TruncatingText } from '@instech/components';
import { openEventModal } from '../modal/eventModal';
import { TimelineAnchor, VerticalPath } from './core';
import { EventDate } from './Event';

interface RightProp { isRight?: boolean; }
interface RightOrBlankProps extends RightProp { isBlank?: boolean; }
interface StyledEventProps extends RightOrBlankProps {
  eventType: eventTypeProp;
  isBlank?: boolean;
  includeInReport: boolean;
}
interface EventTextProps extends RightOrBlankProps {
  isBold?: boolean;
}

const Container = styled.div<RightOrBlankProps>`
  display: flex;
  ${props => !props.isRight && css`
    grid-area: left;
    justify-content: flex-end;
    padding-right: 14px;
  `};
  ${props => props.isRight && css`
    grid-area: right;
    justify-content: flex-start;
    padding-left: 14px;
  `};
`;

const Event = styled.div<StyledEventProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 64px;
  background-color: ${props => props.includeInReport ? props.theme.white : props.theme.lightGray};
  ${props => !props.includeInReport && css`
    color: #333;
  `};
  border: 1px solid ${props => props.theme.timeline[props.eventType].main};
  border-radius: 6px;
  transition: box-shadow 0.2s ease;
  box-sizing: border-box;
  ${props => !props.isRight && css`
    padding-right: 8px;
  `};
  ${props => props.isRight && css`
    flex-direction: row-reverse;
    padding-left: 8px;
  `};
  &::before {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${props => props.includeInReport ? props.theme.white : props.theme.lightGray};
    border: 1px solid;
    border-color: 
      ${props => props.theme.timeline[props.eventType].main} 
      ${props => props.theme.timeline[props.eventType].main} 
      transparent transparent;
    transition: box-shadow 0.2s ease;
    ${props => !props.isRight && css`
      right: -0.70rem;
      transform: rotate(45deg);
    `};
    ${props => props.isRight && css`
      left: -0.70rem;
      transform: rotate(225deg);
    `};
  }
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${props => props.theme.border.gray};
    ${props => !props.isRight && css`
      right: -27px;
    `};
    ${props => props.isRight && css`
      left: -27px;
    `};
  }
  @media (hover: hover) { 
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.18);
      &::before {
        box-shadow: ${props => `${!props.isRight ? '4px ' : '2px'} -2px 2px 0 rgba(0,0,0,0.06)`};
      }
    }
  }
`;

const EventDetails = styled.div<RightProp>`
  display: grid;
  column-gap: 16px;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
  ${props => !props.isRight && css`
    grid-template-areas: "type text status"; 
    grid-template-columns: 32px 1fr auto;
    padding-right: 8px;
  `}
  ${props => props.isRight && css`
    grid-template-areas: "text status type"; 
    grid-template-columns: 1fr auto 32px;
    padding-left: 8px;
  `};
`;

const EventTypeMarker = styled.div<StyledEventProps>`
  grid-area: type;
  width: 32px;
  height: 100%;
  background-color: ${props => props.theme.timeline[props.eventType].main};  
  border-radius: ${props => !props.isRight ? '3px 0 0 3px' : '0 3px 3px 0'};
  ${showBackgroundOnPrint}
`;

const EventText = styled.span<EventTextProps>`
  grid-area: text;
  margin-right: auto;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0px;
  overflow-wrap: break-word;
  width: 100%;
  overflow: hidden;
  ${props => props.isBlank && css`
    color: grey;
    font-style: italic;
  `};
  ${props => props.isBold && css`
    font-weight: bold;
  `}
`;

interface EventStatusProps {
  includeInReport: boolean;
}
const EventStatus = styled.span<EventStatusProps>`
  grid-area: status;
  background-color: ${props => props.theme.timeline.Occurrence.main};
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  text-transform: uppercase;
  ${props => !props.includeInReport && css`
    opacity: 80%;
  `};
`;

const SubName = styled.div`
  font-size: 12px;
`;

interface Props {
  event: TimelineEventProps;
  prevItem: any;
  isRight?: boolean;
  elsewhere?: boolean;
  canEdit: boolean;
  onRemove: {
    func: (value: string) => any;
    value: string;
  };
  onAddNew: (value: TimelineEventProps) => any;
  displayInUtcTime: boolean;
}
export const TimelineEvent = ({
  event,
  prevItem,
  isRight = false,
  elsewhere = false,
  canEdit,
  onRemove,
  onAddNew,
  displayInUtcTime
}: Props) => {
  const { open } = useModalContext();
  const { locations } = useTimelineContext();
  const viewEventModal = openEventModal({ id: event.id, mode: 'view', canEdit, locations, onAddNew });
  const editEventModal = openEventModal({ id: event.id, mode: 'edit', canEdit, locations, onAddNew });

  // Work through all the variables here, so that the components know what to do.
  // It needs to work through ordinary vessel activities; activities with custom names, and
  // Occurrences with their name written in sub-text all in the same place here.
  const activityType = event.typeOfActivity === 'Custom' ? event.customActivityName : event.typeOfActivity;
  const eventName = event.customActivityName || event.name;
  const eventType = event.eventType || 'None';
  const showSubName = eventType === 'Occurrence';
  const showStatus = event.timeStatus === 'Estimate';
  const locationBefore = prevItem && prevItem.type === 'location';
  const testid = !isRight ? 'timeline-event-left' : 'timeline-event-right';
  const isIncludedInReport = event.includeInReport === undefined || event.includeInReport;

  // If the event is Elsewhere, render opposite of the voyage path
  const isOtherSide = elsewhere ? !isRight : isRight;

  return (
    <TimelineAnchor padding="6px" id={event.id}>
      <EventDate
        eventType={eventType}
        utcDate={event.utcEventDate || ''}
        hasTime={(event.hasTime || event.eventTime) as boolean}
        localTimeOfDay={event.eventTime}
        localDate={event.eventDate || ''}
        displayInUtcTime={displayInUtcTime}
      />
      <Container isRight={isOtherSide}>
        <Event eventType={eventType} isRight={isOtherSide} data-testid={testid} includeInReport={isIncludedInReport}>
          <EventDetails isRight={isOtherSide} onClick={() => open(viewEventModal)}>
            <EventTypeMarker eventType={eventType} isRight={isOtherSide} includeInReport={isIncludedInReport} />
            <EventText isBlank={!event.typeOfActivity}>
              {activityType || 'No activity given'}
              {(showSubName && eventName) && <SubName><TruncatingText text={eventName} width="100%" /></SubName>}
            </EventText>
            {showStatus && <EventStatus includeInReport={isIncludedInReport}>{event.timeStatus}</EventStatus>}
          </EventDetails>
          {eventType !== 'Occurrence' && canEdit && (
            <ContextMenu
              id={event.id}
              options={[
                {
                  icon: <Add />,
                  label: 'New event at this date',
                  onClick: () => onAddNew(event)
                },
                {
                  icon: <Edit />,
                  label: 'Edit event',
                  onClick: () => open(editEventModal)
                },
                {
                  icon: <Trash />,
                  label: 'Delete',
                  onClick: () => onRemove.func(onRemove.value)
                }
              ]}
            />
          )}
        </Event>
      </Container>
      {elsewhere && <VerticalPath isRight={isRight} elsewhere={elsewhere} locationBefore={locationBefore} />}
    </TimelineAnchor>
  );
};
