import { CheckboxControlled } from '@instech/components';
import { useField } from 'formik';
import {
  FC, useCallback, useMemo
} from 'react';
import styled from 'styled-components';

const CenteredCheckbox = styled(CheckboxControlled)`
  justify-content: center;

  & > div {
    justify-content: center;
  }
`;

interface ClaimCheckboxProps {
  value: string;
}
export const ClaimCheckbox: FC<ClaimCheckboxProps> = ({ value }) => {
  const [{ value: selectedValues },, { setValue, setTouched }] = useField<string[]>('selectedClaims');
  const checked = useMemo(() => selectedValues.includes(value), [selectedValues, value]);

  const handleChange = useCallback(() => {
    setTouched(true);
    if (checked) {
      setValue(selectedValues.filter(val => val !== value));
    } else {
      setValue([...selectedValues, value]);
    }
  }, [checked, selectedValues, setTouched, setValue, value]);

  return (
    <CenteredCheckbox name={`selected-claims-${value}`} noErrors noTopLabel selected={checked} onChange={handleChange} />
  );
};
