import PropTypes from 'prop-types';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useImmediateFeedbackService } from '@/services/immediateFeedbackServices';
import { RichTextFormSaveDraftAndPublish } from
  '../../shared/Form/RichTextForm/RichTextFormSaveDraftAndPublish';
import { ImmediateFeedbackView } from './ImmediateFeedbackView';

export const ImmediateFeedbackTool = ({ readonly }) => {
  const caseId = useCaseIdFromUrl();
  const [immediateFeedback, updateImmediateFeedback, publishImmediateFeedback] = useImmediateFeedbackService(caseId);

  if (!immediateFeedback) {
    return <Loader />;
  }

  if (immediateFeedback.isPublished || readonly) {
    return (
      <ImmediateFeedbackView data={immediateFeedback} />
    );
  }

  const onSave = async updatedFeedback => {
    await updateImmediateFeedback(updatedFeedback);
  };

  const onPublish = async () => {
    await publishImmediateFeedback();
  };

  return (
    <RichTextFormSaveDraftAndPublish
      data={immediateFeedback}
      headerText="Add your inital information about the damage"
      onSave={onSave}
      onPublish={onPublish}
    />
  );
};
ImmediateFeedbackTool.propTypes = {
  readonly: PropTypes.bool
};

export const openImmediateFeedbackTool = ({ readonly }) => ({
  component: ImmediateFeedbackTool,
  options: {
    title: 'Immediate Feedback',
    footer: true
  },
  args: { readonly }
});

openImmediateFeedbackTool.propTypes = {
  readonly: PropTypes.bool
};
