import styled from 'styled-components';
import { TextArea } from '@instech/components';
import {
  Table, TableRow, TableCell
} from '@/components/shared/Table';
import { clampNumber } from '@/utils/number';
import { StatusTypeDropdownField } from '@/components/shared/Form/FormFields';
import {
  NumberField, CheckboxField, TableHeader
} from './Components';

const BlueText = styled.span`
  color: ${props => props.theme.marineBlue};
`;

// Clamp the days showing to a min and max value
const headerText = 'If carried out separately the above work would have required a total of; ';
const daysText = (totalSeparateDays: number) => `${clampNumber(totalSeparateDays)} days to be completed.`;

interface Props { totalSeparateDays: number; }
export const TableFields = ({ totalSeparateDays }: Props) => (
  <Table layout="minmax(min-content, 200px) 220px minmax(280px, 1fr) auto">
    <TableHeader>
      {headerText}
      <BlueText>{daysText(totalSeparateDays)}</BlueText>
    </TableHeader>
    <TableRow even>
      <TableCell>
        <NumberField
          name="separateDays.dryDock"
          label="Dry Dock"
        />
      </TableCell>
      <TableCell>
        <StatusTypeDropdownField
          name="separateDaysDryDockTimeStatus"
          label="Status"
        />
      </TableCell>
      <TableCell>
        <TextArea
          name="separateDaysDryDockComment"
          placeholder="..."
          maxLength={500}
          label="Comment"
        />
      </TableCell>
      <TableCell>
        <CheckboxField
          name="includeSeparateDaysDryDockComment"
          label="Include comment in report?"
        />
      </TableCell>
    </TableRow>
    <TableRow even>
      <TableCell>
        <NumberField
          name="separateDays.afloat"
          label="Afloat"
        />
      </TableCell>
      <TableCell>
        <StatusTypeDropdownField
          name="separateDaysAfloatTimeStatus"
          label="Status"
        />
      </TableCell>
      <TableCell>
        <TextArea
          name="separateDaysAfloatComment"
          placeholder="..."
          maxLength={500}
          label="Comment"
        />
      </TableCell>
      <TableCell>
        <CheckboxField
          name="includeSeparateDaysAfloatComment"
          label="Include comment in report?"
        />
      </TableCell>
    </TableRow>
  </Table>
);
