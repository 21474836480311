import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useModalContext } from '@/components/modal/ModalContext';
import { BoxButton, Divider } from '@instech/components';
import { RichTextView } from '../../shared/Form/RichTextView';
import { Pane } from '../../shared/Pane';

const defaultContent = {
  images: null,
  text: '*No feedback added yet*'
};

const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-end;
& > button + button {
  margin-left: 16px;
}
`;

interface Props {
  data: {
    text: string | null;
    images: string[] | null;
  }
}

export const ImmediateFeedbackView: FunctionComponent<Props> = ({ data }) => {
  const noContent = (!data.images || data.images?.length === 0) && !data.text?.trim();
  const modal = useModalContext();
  return (
    <Pane title="Initial information about the damage">
      <RichTextView data={noContent ? defaultContent : data} />
      <Divider />
      <ButtonWrapper>
        <BoxButton
            onClick={modal.close}
          >
          Close
        </BoxButton>
      </ButtonWrapper>
    </Pane>
  );
};
