/* eslint-disable react/forbid-prop-types */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { InfoCircle } from '@instech/icons';
import { BoxButton } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { openImmediateFeedbackTool } from '@/components/tools/ImmediateFeedback/ImmediateFeedbackTool';
import { UserInfo } from '@/types';
import { ToolPane } from '../core/ToolPane';

const HandlerDetails = styled.div`
  color: ${props => props.theme.marineBlue};
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Detail = styled.div`
  font-size: 13px;
  margin-top: 5px;
`;

const Email = styled.a`
  text-decoration: underline;
  font-size: 13px;
  margin-top: 5px;
`;

const ButtonArea = styled.div`
  padding-top: 20px;
`;

const UnassignedText = styled.span`
  color: ${props => props.theme.black};
  font-style: italic;
`;

interface Props {
  contactInfo?: UserInfo;
  readonlyFeedback?: boolean;
  titleText: string;
  unassignedText?: string;
  buttonText: string;
}
export const FeedbackPane: FunctionComponent<Props> = (
  { contactInfo, readonlyFeedback = false, titleText, buttonText, unassignedText }
) => {
  const { open } = useModalContext();
  const showFeedback = openImmediateFeedbackTool({ readonly: readonlyFeedback });

  return (
    <ToolPane title={titleText}>
      { !contactInfo && (
        <UnassignedText>{unassignedText}</UnassignedText>
      )}
      { contactInfo && (
      <HandlerDetails>
        <Name>{contactInfo.displayName}</Name>
        <Detail>{contactInfo.company}</Detail>
        <Email href={`mailto:${contactInfo.email}`}>{contactInfo.email}</Email>
        <Detail>{contactInfo.phoneNumber || ''}</Detail>
      </HandlerDetails>
      )}
      <ButtonArea>
        <BoxButton
          startIcon={readonlyFeedback ? <InfoCircle /> : undefined}
          onClick={() => open(showFeedback)}>
          {buttonText}
        </BoxButton>
      </ButtonArea>
    </ToolPane>
  );
};
