import { useModalContext } from '@/components/modal/ModalContext';
import {
  FormField,
  Divider,
  ButtonGroup, BoxButton, Loader,
  FormRow
} from '@instech/components';
import { useCompanyParticulars } from '@/services/companyParticularsServices';
import { NoContent } from '../../shared/NoContent';
import { ParticularField } from '../core/ParticularField';

export const CompanyParticularsReadOnlyView = () => {
  const { close } = useModalContext();
  const { data: particulars } = useCompanyParticulars();

  if (!particulars) {
    return <Loader />;
  }

  return (
    <>
      <div>
        {particulars.length === 0 && <NoContent text="No company particulars added" />}
        {particulars.map(item => (
          <FormRow key={item.id} marginBottom="12px">
            <FormField>
              <ParticularField
                title={item.title}
                description={item.description}
                source={item.source}
              />
            </FormField>
          </FormRow>
        ))}
      </div>
      <Divider padding="0px" />
      <ButtonGroup alignRight>
        <BoxButton onClick={close}>Done</BoxButton>
      </ButtonGroup>
    </>
  );
};
