import styled from 'styled-components';
import { EyeSlash } from '@instech/icons';

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 465px;
`;

const Heading = styled.h1`
  font-size: 36px;
  font-weight: unset;

  /* line-height: 77px; */
  /* font-weight: bold; */
  color: ${props => props.theme.marineBlue};
`;

const Text = styled.p`
  font-size: 20px;
  color: ${props => props.theme.marineBlue};
`;

const StyledEyeSlash = styled(EyeSlash)`
  color: ${props => props.theme.marineBlue};
`;

// eslint-disable-next-line max-len
const message = 'Due to GDPR requirements, contact data is hidden for all involved parties when the case is closed. This data will be permanently deleted 10 years after the case is closed.';

export const ContactsHiddenMessage = () => (
  <Wrapper>
    <StyledEyeSlash height="96px" width="96px" />
    <Heading>Contacts hidden</Heading>
    <Text>{message}</Text>
  </Wrapper>
);
