import { FunctionComponent } from 'react';
import { useCase } from '@/services/dashboardServices';
import { Loader } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useUserInfo } from '@/services/userInfoService';
import { FeedbackPane } from './FeedbackPane';

const EditFeedbackContainer = () => {
  const caseId = useCaseIdFromUrl();
  const { data: caseData, error: caseError } = useCase(caseId);
  const claimsHandlerId = caseData?.users?.primaryClaimsHandlerId;
  const { data: userInfo, error: userInfoError } = useUserInfo(claimsHandlerId);
  if (caseError || userInfoError) {
    throw new Error(caseError || userInfoError);
  }
  if (!userInfo && claimsHandlerId) return <Loader />;

  return (
    <FeedbackPane
      contactInfo={userInfo}
      titleText="Your Claims Handler"
      unassignedText="No claim handler currently assigned"
      buttonText="Immediate Feedback to Claims Handler"
    />
  );
};

const ReadonlyFeedbackContainer = () => {
  const caseId = useCaseIdFromUrl();
  const { data: caseData, error: caseError } = useCase(caseId);
  const claimsHandlerId = caseData?.users?.primaryClaimsHandlerId;
  const { data: userInfo, error: userInfoError } = useUserInfo(claimsHandlerId);

  if (caseError || userInfoError) {
    throw new Error(caseError || userInfoError);
  }

  if (!userInfo && claimsHandlerId) return <Loader />;
  return (
    <FeedbackPane
      contactInfo={userInfo}
      titleText="Your Claims Handler"
      unassignedText="No claim handler currently assigned"
      buttonText="Immediate Feedback to Claims Handler"
      readonlyFeedback
    />
  );
};

interface Props {readonly?: boolean;}
export const FeedbackContainer: FunctionComponent<Props> = ({ readonly = false }) => (
  readonly
    ? <ReadonlyFeedbackContainer />
    : <EditFeedbackContainer />
);
