import { updateCaseSurveyors } from '@/services/userInfoService';
import {
  FormikHelpers, Formik, useFormikContext, Form
} from 'formik';
import {
  FC, useEffect, useMemo, useState
} from 'react';
import { UserPickerMultiPrimary } from '@instech/components';
import {
  Case, Guid, UserInfo
} from '@/types';
import { useModalContext } from '@/components/modal/ModalContext';
import { ErrorMessage } from '@/components/shared/Form/core/Components';
import { ModalFormButtons } from '@/components/shared/FormButton/FormButton';
import { accessFormValidationSchema } from '../validationSchema';
import { createInitialValues, AccessFormValues } from '../utils';
import { PrimaryErrorDisplay } from './core/PrimaryErrorDisplay';
import { AccessNotification } from './core/AccessNotification';

interface InnerFormProps {
  allowedSurveyors: UserInfo[];
  isPending: boolean;
  hasSaveError: boolean;
}
const SurveyorsModalInnerForm: FC<InnerFormProps> = ({ allowedSurveyors, isPending, hasSaveError }) => {
  const [hasClickedSave, setHasClickedSave] = useState(false);
  const { initialValues, setFieldValue, values, dirty, errors, handleSubmit } = useFormikContext<AccessFormValues>();
  const { close } = useModalContext();

  // If the UserPicker only has one row left in it, try to
  // ensure the selection in that row is also the Primary
  useEffect(() => {
    const { users, primaryId } = values;
    // Escape if there is a primary or there is not one UserPicker row
    if (primaryId || users.length !== 1) {
      return;
    }
    // Escape if the last remaining UserPicker row is empty (e.g. '')
    if (typeof users[0] !== 'object') {
      return;
    }
    void setFieldValue('primaryId', users[0].id ?? '');
  }, [setFieldValue, values]);

  const handleSave = () => {
    setHasClickedSave(true);
    handleSubmit();
  };

  const showPrimaryError = !!(hasClickedSave && errors.primaryId);

  return (
    <Form>
      <PrimaryErrorDisplay showError={showPrimaryError} />
      <UserPickerMultiPrimary
        name="users"
        namePrimary="primaryId"
        options={allowedSurveyors}
        noErrorColors={!hasClickedSave}
        noErrors
      />
      <AccessNotification initialUsers={initialValues.users} selectedUsers={values.users} />
      <ModalFormButtons
        onSave={handleSave}
        onCancel={close}
        isPending={isPending}
        isDisabled={!dirty || isPending}
      />
      {hasSaveError && (
        <ErrorMessage>Error when saving surveyors.</ErrorMessage>
      )}
    </Form>
  );
};

interface Props {
  caseId: Guid;
  report: Case;
  allowedSurveyors: UserInfo[];
}
export const EditSurveyorsModal: FC<Props> = ({ caseId, report, allowedSurveyors }) => {
  const [isPending, setIsPending] = useState(false);
  const [hasSaveError, setHasSaveError] = useState(false);

  const initialValues = useMemo(() => createInitialValues(report, allowedSurveyors), [report, allowedSurveyors]);
  const handleSubmit = async (values: AccessFormValues, helpers: FormikHelpers<AccessFormValues>) => {
    const { users, primaryId } = values;

    // reset errors and enter save pending mode
    setHasSaveError(false);
    setIsPending(true);

    // remove empty rows from the array of selected Ids
    const validUsers = users.filter(item => !!item);
    const validUserIds = validUsers.map(user => user.id);

    if (!primaryId || validUsers.length === 0) {
      setIsPending(false);
      return;
    }

    const response = await updateCaseSurveyors(caseId, validUserIds, primaryId);
    setHasSaveError(response.status > 201);
    setIsPending(false);

    // Reset form with just the valid values from the form, so empty rows will vanish
    helpers.resetForm({ values: { users: validUsers, primaryId } });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={accessFormValidationSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      <SurveyorsModalInnerForm
        allowedSurveyors={allowedSurveyors}
        isPending={isPending}
        hasSaveError={hasSaveError}
      />
    </Formik>
  );
};
