import { FunctionComponent, useState } from 'react';
import 'styled-components/macro';
import { useRouteMatch } from 'react-router';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useModalContext } from '@/components/modal/ModalContext';
import { useTimelineEventService } from '@/services/timelineServices';
import { Loader } from '@instech/components';
import { useReportIdFromUrl } from '@/hooks/useReportIdFromUrl';
import { useNewestReport } from '@/services/versionsService';
import { ViewEvent } from './views/ViewEvent';
import { EditEventForm } from '../EventForm/EditEventForm';
import { EditOccurrenceForm } from './views/EditOccurrenceForm';
import { ViewOccurrence } from './views/ViewOccurrence';

const EventNotFound = () => (
  <div css="font-style: italic;">
    Event does not exist
  </div>
);

interface EventModalProps {
  id: string | null;
  mode: 'view' | 'edit';
  locations: any;
  canEdit: boolean;
  onAddNew: (args: any) => any;
}
export const EventModal: FunctionComponent<EventModalProps> = ({ id, mode = 'view', canEdit, locations, onAddNew }) => {
  const caseId = useCaseIdFromUrl();

  /*
  *  Determine if we should fetch an archived version of the timeline or not.
  *  If this component is used on the Claims handler dashboard, the latest archived report should be used.
  *  If this component is used in the report view, the reportId in the URL should be used.
  *  In all other cases, the current timeline will be used.
  */
  const { data: latestReport } = useNewestReport(caseId);
  const reportIdFromUrl = useReportIdFromUrl();
  const dashboardMatch = useRouteMatch('/case/:id/dashboard');
  const reportId = dashboardMatch?.isExact ? latestReport?.archivedId : reportIdFromUrl;

  const [eventMode, setEventMode] = useState(mode);
  const { data: eventData, updateEvent, removeEvent, updateOccurrenceTime } = useTimelineEventService(caseId, id as string, reportId);

  const { close } = useModalContext();

  const switchToView = () => setEventMode('view');
  const switchToEdit = () => setEventMode('edit');

  const onRemoveHandler = async () => {
    await removeEvent();
    close();
  };

  if (eventData?.status === 'notfound') {
    return <EventNotFound />;
  }

  if (!eventData?.event) {
    return <Loader />;
  }
  const { event } = eventData;

  // Occurrences shouldn't be able to open the edit modal
  if (event.eventType === 'Occurrence') {
    return (
      <div data-testid="occurrence-modal">
        {eventMode === 'view' && (
          <ViewOccurrence
            event={event as any}
            canEdit={canEdit}
            onClose={close}
            onEdit={switchToEdit}
            isReport={!!(reportId)}
          />
        )}
        {eventMode === 'edit' && (
          <EditOccurrenceForm
            occurrenceEvent={event}
            onClose={switchToView}
            occurrenceId={id as string}
            onSubmit={values => updateOccurrenceTime(values)}
          />
        )}
      </div>
    );
  }

  return (
    <div data-testid="event-modal">
      {eventMode === 'view' && (
        <ViewEvent
          event={event as any}
          onEdit={switchToEdit}
          onClose={close}
          onRemove={onRemoveHandler}
          canEdit={canEdit}
          onAddNew={onAddNew}
          isReport={!!(reportId)}
        />
      )}
      {eventMode === 'edit' && (
        <EditEventForm
          event={event}
          onSubmit={updateEvent}
          onClose={switchToView}
          locations={locations}
        />
      )}
    </div>
  );
};

export const openEventModal = ({ id = null, mode = 'view', locations, canEdit, onAddNew, ...restOptions }: EventModalProps) => ({
  component: EventModal,
  options: {
    title: 'Timeline details',
    footer: true,
    size: 'medium'
  },
  args: { id, mode, locations, canEdit, onAddNew, ...restOptions }
});
