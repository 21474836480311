import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 12px; /* Close button has its own padding */
  border-bottom: 1px solid ${props => props.theme.marineBlue};
`;

const Title = styled.span`
  color: ${props => props.theme.deepAzureBlue};
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 13px;
`;

const Content = styled.div`
  padding: 20px;
`;

interface ToolPaneProps {
  title: string;
}
export const ToolPane: FunctionComponent<ToolPaneProps> = ({ title, children }) => (
  <>
    <Header>
      <Title>{title}</Title>
    </Header>
    <Content>
      {children}
    </Content>
  </>
);
