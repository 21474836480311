import {
  FunctionComponent, useContext, useEffect
} from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  Immediate, Phone, SendMessage, UserSingle, Pen
} from '@instech/icons';
import { UserInfo } from '@/types';
import { useCaseNotifications } from '@/services/caseNotificationsServices';
import { useModalContext, useTypedModal } from '@/components/modal/ModalContext';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { openImmediateFeedbackTool } from '@/components/tools/ImmediateFeedback/ImmediateFeedbackTool';
import { NotifyIcon, NotifyTooltip } from '@/components/shared/Tooltip';
import { IconButton } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { DashboardPane } from './DashboardPane';
import { openSurveyorsModal } from '../SurveyorsModal';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UnassignedText = styled.span`
  color: ${props => props.theme.black};
  font-style: italic;
`;

const MarginTop = styled.div`
  margin-top: 32px;
`;

const CompanyLogo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

const ContactName = styled.div`
  font-size: 18px;
  line-height: 23px;
  color: ${props => props.theme.phldrBlue1};
`;

const ContactInfo = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin-top: 16px;
`;

const IconWrapper = styled.div`
  margin-right: 20px;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

const TextAboveButton = styled.div`
  font-size: 14px;
  color: ${props => props.theme.marineBlue};
  margin-bottom: 16px;
`;

const NotifyIconContainer = styled.div`
  position: relative;
  display: inline-block;
`;

interface ButtonProps { notification?: boolean; }
const FeedbackButton = styled(NewBoxButton) <ButtonProps>`
  ${props => props.notification && css`
    background-color: ${props.theme.green};
    border-color: ${props.theme.green};
    color: ${props.theme.marineBlue};

    @media (hover: hover) {
      &:hover&:not(:disabled) {
        background-color: ${props.theme.green75};
        border-color: ${props.theme.green75};

      };
    };
  `}
`;

const EditButton = () => {
  const { open } = useTypedModal(openSurveyorsModal());
  return (
    <IconButton smallIcon width="28px" height="28px" icon={<Pen />} onClick={open} />
  );
};

interface Props {
  contactInfo?: UserInfo;
  readonlyFeedback?: boolean;
  titleText: string;
  unassignedText?: string;
  buttonText: string;
}
export const FeedbackPane: FunctionComponent<Props> = (
  { contactInfo, readonlyFeedback = false, titleText, buttonText, unassignedText }
) => {
  const caseId = useCaseIdFromUrl();
  const { open } = useModalContext();
  const themeContext = useContext(ThemeContext);
  const showFeedback = openImmediateFeedbackTool({ readonly: readonlyFeedback });
  const { data: notifications, dismissImmediateFeedback } = useCaseNotifications(caseId);
  const hasNotification = notifications?.hasImmediateFeedbackNotification;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [hasNotification]);

  const onClick = () => {
    void dismissImmediateFeedback();
    open(showFeedback);
  };

  return (
    <DashboardPane title={titleText} icon={<UserSingle />} padding="24px">
      {!contactInfo && (
        <Flex>
          <UnassignedText>{unassignedText}</UnassignedText>
          <EditButton />
        </Flex>
      )}
      {contactInfo && (
        <>
          <Flex>
            <CompanyLogo>{contactInfo.company}</CompanyLogo>
            <EditButton />
          </Flex>
          <MarginTop>
            <ContactName>{contactInfo.displayName}</ContactName>
            <ContactInfo>
              <IconWrapper><SendMessage /></IconWrapper>
              <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </ContactInfo>
            <ContactInfo>
              <IconWrapper><Phone /></IconWrapper>
              <span>{contactInfo.phoneNumber || ''}</span>
            </ContactInfo>
          </MarginTop>
        </>
      )}
      <MarginTop>
        <TextAboveButton>Initial survey findings and operations</TextAboveButton>
        <NotifyIconContainer>
          {hasNotification && <NotifyIcon id="feedback-notify" message="New feedback available" backgroundColor={themeContext.green} />}
          <FeedbackButton
            startIcon={readonlyFeedback && <Immediate />}
            onClick={onClick}
            notification={hasNotification}
          >
            {buttonText}
          </FeedbackButton>
        </NotifyIconContainer>
      </MarginTop>
      <NotifyTooltip id="feedback-notify" />
    </DashboardPane>
  );
};
