import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BoxButton } from '@instech/components';
import { AddWhite } from '@instech/icons';
import { childrenPropType } from '@/prop-types/custom-prop-types';

const FormContainer = styled.div`
  background-color: #ECF1F5;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 12px; /* Close button has its own padding */
  border-bottom: 1px solid #7F9DB8;
`;

const FormTitle = styled.span`
  color: ${props => props.theme.marineBlue};
  padding-top: 11px;
  padding-bottom: 13px;
`;

const FormBody = styled.div`
  padding: 20px;
`;

export const ToggleInlineForm = ({ title, openLabel, children, startOpen = false }) => {
  const [isOpen, setOpen] = useState(startOpen);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  if (!isOpen) {
    return (
      <BoxButton onClick={open} startIcon={<AddWhite />}>
        {openLabel}
      </BoxButton>
    );
  }

  return (
    <FormContainer>
      <FormHeader>
        <FormTitle>{title}</FormTitle>
      </FormHeader>
      <FormBody>
        {children({ onClose: close })}
      </FormBody>
    </FormContainer>
  );
};
ToggleInlineForm.propTypes = {
  title: PropTypes.string.isRequired,
  openLabel: PropTypes.string.isRequired,
  startOpen: PropTypes.bool,
  children: childrenPropType
};
