import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  FormField, FormRow, DateRangePicker, TextArea
} from '@instech/components';
import {
  Checkbox, CheckboxField, NumberField, StatusTypeDropdownField
} from '@/components/shared/Form/FormFields';
import { NumberOfDays } from './NumberOfDays';

const StyledNumberField = styled(NumberField)`
  min-width: 4rem;
`;

interface NumberWrapperProps {
  name: string;
  label: string;
  placeholder?: string;
}
const NumberWrapper: FunctionComponent<NumberWrapperProps> = ({ name, label, placeholder = '' }) => (
  <StyledNumberField
    name={name}
    label={label}
    placeholder={placeholder}
    min={0}
  />
);

const DatesPane = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.background.primary};
`;

const StyledFormField = styled(FormField)`
  & label {
    font-size: 13px;
  }
`;

const StyledFormRow = styled(FormRow)`
  justify-content: space-between;
`;

const StyledCheckbox = styled(Checkbox)`
  min-width: 12rem;
  width: auto;
  text-align: center;
  > ${CheckboxField} {
    justify-content: space-around;
  }
`;

interface Props {
  totalActualDays: number;
  ownersWork?: boolean;
}
export const DurationFields: FunctionComponent<Props> = ({ totalActualDays, ownersWork }) => (
  <DatesPane>
    <FormRow gutter="20px">
      <StyledFormField span={5}>
        <DateRangePicker
          name="duration"
          startLabel={ownersWork ? 'Commencement of owners work' : 'Commencement of repairs'}
          endLabel={ownersWork ? 'Completion of owners work' : 'Completion of repairs'}
        />
      </StyledFormField>
      <StyledFormField span={2}>
        <NumberWrapper
          name="actualDays.dryDock"
          label="Actual days dry dock"
        />
      </StyledFormField>
      <StyledFormField span={2}>
        <NumberWrapper
          name="actualDays.afloat"
          label="Actual days afloat"
        />
      </StyledFormField>
      <StyledFormField span={1}>
        <NumberOfDays value={totalActualDays} />
      </StyledFormField>
      <StyledFormField span={2}>
        <StatusTypeDropdownField
          name="durationTimeStatus"
          label="Time status"
        />
      </StyledFormField>
    </FormRow>
    <StyledFormRow gutter="10px">
      <TextArea
        name="durationComment"
        label="Comments"
        maxLength={500}
        placeholder="..."
      />
      <StyledCheckbox
        name="includeDurationComment"
        label="Include comment in report?"
        noErrors
      />
    </StyledFormRow>
  </DatesPane>
);
